import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/SEO/SEO"
import NavBar from "../../components/NavBar/NavBar"
import Layout from "../../components/Layout"

const navRoadArray = [{ name: "About", link: "/about/" }]

function TermsandConditions(props) {
  const NoADS = props.pageContext
  return (
    <Layout location={props.location} NoADS={NoADS}>
      <SEO
        title="Unitpedia.com - About"
        description="Unitpedia About us information page."
      />
      <NavBar listPages={navRoadArray} removeADS={true} />
      <section className="container">
        <h1>About</h1>
        <p>
          Unitpedia.com contains information and many online tools. <br />
          Help you to convert units, math calculates, other calculations, and
          many online tools like names generator, fonts, emojis, and fancy text
          for social media or else thing.
          <br />
          Please read the <Link to="/privacy/">Privacy policy</Link> pages.
        </p>
        <br />
        <p>
          Please feel free to contact us about: Content, Corrections, issues,
          mistakes, Suggestions.
          <br />
          <a href="mailto:contact@unitpedia.com">Contact@unitpedia.com</a>
        </p>

        <br />
      </section>
    </Layout>
  )
}

export default TermsandConditions
